@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800&display=swap");

@font-face {
  font-family: "Clash Display Variable";
  src: url("/public/ClashDisplay-Variable.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Clash Display Variable";
  src: url("/public/ClashDisplay-Semibold.otf");
  font-weight: 600;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply leading-[normal] m-0;
}

body .vertical-timeline-element-content {
  background-color: transparent;
  margin: 0px;
  padding: 0px !important;
  border-radius: 0px;
  box-shadow: none;
}

body .custom-timeline::before {
  position: absolute;
  top: 5%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-left: 2px dashed #1d245e;
  width: 0px;
  background-color: none;
  height: 90%;
}

body .custom-timeline::after {
  content: "";
  position: absolute;
  top: 94.3%;
  left: 49.8%;
  width: 15px;
  height: 15px;
  border-left: 3.5px solid transparent;
  border-right: 3.5px solid transparent;
  border-top: 6px solid transparent;
  background-image: url("/public/arrow-1.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateX(-50%);
}

@media (max-width: 960px) {
  body .custom-timeline::before {
    left: 0;
  }
}

@media (max-width: 960px) {
  body .custom-timeline::after {
    left: 0;
  }
}

*,
::before,
::after {
  border-width: 0;
}

.ideabot-chatBtn {
  z-index: 999;
}

.ideabot-chatBtn img {
  height: auto !important;
}

.react-slideshow-container .nav:first-child {
  text-align: right;
}
